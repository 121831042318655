/**
 * VARIABLE
 */

// Color
$yellow: rgb(255,213,0);
$lightgrey: rgb(240,240,240);
$darkgrey: rgb(119,119,119);
$white: rgb(255,255,255);
$black: rgb(0,0,0);

$footertext: rgb(179, 179, 179);
$footerbg: rgb(102,102,102);

//Sizes
$wrapper-width: 1024px;
$nav-height: 75px;


/*
 * FUNCTIONS
 */

/*
    Helper Function - Strips unit
    http://stackoverflow.com/questions/12328259/how-do-you-strip-the-unit-from-any-number-in-sass/12335841#12335841
*/
@function strip-units($number) {
	@return $number / ($number * 0 + 1);
}

/*
    Calculate REM
    Can be used with unit (rem(40px)) and without (rem(40))
 */
@function rem($size) {
	$size: strip-units($size);
	$remSize: $size / 16;
	@return #{$remSize}rem;
}


/**
 * MIXIN
 */

/* Media Queries */
$phablet-width: 480px;
$tablet-width: 768px;
$laptop-width: 980px;
$desktop-width: 1150px;

$phone: "(max-width: #{$phablet-width})";
$phablet: "(min-width: #{$phablet-width + 1}) and (max-width: #{$tablet-width})";
$tablet: "(min-width: #{$tablet-width + 1}) and (max-width: #{$laptop-width})";
$laptop: "(min-width: #{$laptop-width + 1}) and (max-width: #{$desktop-width})";
$desktop: "(min-width: #{$desktop-width})";

// Shortcuts
$large: #{$laptop}, #{$desktop};
$medium: #{$tablet};
$small: #{$phone}, #{$phablet};

// Alias for Nav
$nav-desktop: #{$laptop}, #{$desktop};
$nav-mobile: #{$phone}, #{$phablet}, #{$tablet};

/*
	@media #{$small}, ${$desktop} {

	}
 */

@mixin custom($size) {
	@media only screen and (max-width: #{$size}) {
	@content;
	}
}

/*

@mixin phone {
	@media only screen and (max-width: #{$phablet-width}) {
	@content;
	}
}

@mixin phablet {
	@media only screen and (max-width: #{$tablet-width}) {
	@content;
	}
}

@mixin tablet {
	@media only screen and (max-width: #{$laptop-width}) {
	@content;
	}
}

@mixin laptop {
	@media only screen and (max-width: #{$desktop-width}) {
	@content;
	}
}

@mixin desktop {
	@media only screen and (min-width: #{$desktop-width}) {
	@content;
	}
}
*/

/* Usage:
@include tablet {

}
*/

@mixin transition( $timing: .2s ) {
	transition: all #{$timing} ease;
}


/**
 * Hacks
 */

// Style Rubber-Band
html {
	background: linear-gradient(to bottom, rgb(102,102,102) 10%, $yellow 90%);  // Some strange behaviour on horizontal rubber band in Safari - add smooth transition
	background-color: $yellow;
}

::selection {
    color: $black;
    background: $yellow;
}

/*
@keyframes anim-effect-3-1 {
	0% {
		transform: translate3d(0, 0, 0);
	}
	25%, 75% {
		transform: translate3d(0, -100%, 0);
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}
	100% {
		transform: translate3d(0, -200%, 0);
	}
}
*/

/**
 * General
 */

.overlay {

	//opacity: 0;
	//visibility: hidden;
	@include transition(.5s);

	position: fixed;
	top: 0;
	left: 0;
	bottom: -1.5vw;
	width: 105vw;
	background-color: $yellow;
	z-index: 1000;
	transform: translateY(100%) rotate(-1.8deg);

	background: {
		position: 50% 50%;
		repeat: no-repeat;
	}

	z-index: 110;
}

.preload {

	/*
	*:not(.overlay) {
		transition: none !important;
	}
	*/

	.overlay {
		opacity: 1;
		visibility: visible;
		@include transition(.3s); // sync with js (timeout set)

		transform: translateY(0);

		//animation: anim-effect-3-1 1.5s cubic-bezier(0.550, 0.055, 0.675, 0.190) forwards;
	}

}

@viewport {
	width: device-width;
}


html {
	text-size-adjust: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-y: scroll;
	font-size: 100%;
	font-family: 'Raleway', sans-serif;
}

body {
	font-variant-ligatures: no-common-ligatures;
	line-height: 1.5;

	font-family: "effra",sans-serif;

	background-color: $white;
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
  	min-height: 100vh;
}

img {
	width: 100%;
	max-width: 100%;
	height: auto;
}

.pdf {
	position: relative;
    margin-left: 22px;

	&:before {
		position: absolute;
	    left: -22px;
	    font-family: FontAwesome;
	    content: "\f1c1";
	}
}

.btn {
	@extend %link;
	display: inline-block;
	padding: .4rem 1.2rem !important;
	background-color: transparent;
	font-size: 1rem;
	font-weight: 400;
	margin: 1rem 0 1.2rem !important;

	@include transition();

	&:hover {
		background-color: $darkgrey !important;
		border-color: $darkgrey !important;
		color: $white !important;
	}

	+ .btn {
  		margin-left: 1.5rem !important;
  	}
}


/**
 * Typo
 */

h1, h2, h3, h4, h5, h6 {
	margin-top: 2rem;
	margin-bottom: 1rem;
	&:first-child {
		margin-top: 0;
	}
	font-weight: 900;
	word-wrap: break-word;
	text-transform: uppercase;
	letter-spacing: 1px;
  	word-break: break-word;
}

h1 {
	@media #{$large}, #{$medium} {
		font-size: 2.4rem;
		letter-spacing: 2.8px
	}

	@media #{$small} {
		font-size: 1.8rem; /* 1.5rem */
		letter-spacing: 1.8px
	}
}
h2 { font-size: 1.3rem; }
h3 { font-size: 1rem; }

a {
	text-decoration: none;
	color: $black;
}

p {
	margin-bottom: 1rem;

	&:last-child {
		margin-bottom: 0;
	}
}


strong {
	font-weight: 700;
}

ul {
	list-style: outside;
	padding-left: 1.4rem;
}

.lead {
	@media #{$large} {
		font-size: 1.3rem;
	}

	@media #{$small}, #{$medium} {
		font-size: 1.1rem;
	}

	&--contact {
		@media #{$large} {
			width: 60%;
		}
	}
}

%link {
	margin-top: 1rem;
	border: solid 2px $black;
	border-radius: 1.1rem;
	padding: .3rem .8rem .4rem;
	color: $black;
	cursor: pointer;
	margin: 1rem 1rem 1.2rem;

	@include transition();

	&:hover {
		color: $lightgrey;
		background-color: $black;
		border: solid 2px $black;
	}

	&:active {
		background-color: $yellow;
		color: $black;
		border-color: $yellow;
	}
}


/**
 * Header
 */

.header {
	 @media #{$nav-desktop} {
		position: absolute;
	}

	@media #{$nav-mobile} {
		position: fixed;
	}

	display: flex;
	justify-content: center;

	height: $nav-height;
	/*
	position: relative;
	*/
	top: 0;
	right: 0;
	left: 0;
    width: 100%;
    z-index: 150;
	background-color: $yellow;

	&__inner {
		position: relative;
		display: flex;
		width: 100%;
		justify-content: center;
		//max-width: #{$wrapper-width + 350};
		max-width: #{$wrapper-width + 250};
	}

	&__logo {
	    position: absolute;
		top: 0;
		left: 0;

	    background: {
	    	size: 75%;
	    	image: url(../../dist/images/logo_rgb.svg);
	    	position: center;
	    	repeat: no-repeat;
	    	color: $white;
	    }
	    text-indent: -999em;
	    margin: 0 5% 0 rem(25);
		@media #{$phone} {
			margin-left: rem(15);
		}

	    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.2);
	    z-index: 11;

	    @media #{$nav-desktop} {
			width: rem(180);
			height: rem(110);
		}

		@media #{$nav-mobile} {
			width: rem(140);
			height: #{($nav-height + 10px)};
		}
	}
}


/**
 * Navigation
 */

.nav {
	ul {
		margin: 0;
		padding: 0;

		display: flex;
	}

	li {
		list-style: none;
	}

	.sub-menu {
		display: none;
	}

	.menu-item-has-children > a {
		cursor: s-resize;
	}

	a {
		text-transform: uppercase;
		letter-spacing: .8px;
		padding-bottom: .2rem;
		border-bottom: solid 2px $yellow;
		font-weight: 900;

		&:hover {
			color: $black;
		}
	}

	body &--active { // specifity
		display: block;
	}

	@media #{$nav-mobile} {
		display: none;

		> ul {
			position: fixed;
			top: $nav-height;
			left: 0;
			right: 0;
			bottom: 0;
			padding: rem(35) 5% rem(20);
			background-color: $yellow;

			align-items: center;
			flex-direction: column;

			@media only screen and (min-height: 400px) {
				justify-content: center;
			}

			overflow: scroll;

			> li > a {
				letter-spacing: 1.4px;
			}
		}

		li {
			text-align: center;
			line-height: 2.2;
		}

		.sub-menu {
			display: none; // flex

			&.active {
				display: flex;
			}

			flex-direction: column;

			a {
				font-size: 1rem;
				text-transform: capitalize;
				font-weight: 400;

				&:hover {
					color: $darkgrey;
				}
			}

			li {
				line-height: 1.7;
			}
		}

		a {
			font-size: 1.3rem;
		}

	}

	@media #{$nav-desktop} {

		ul {
			flex-direction: row;
			line-height: $nav-height;
		}

		li {
			padding: 0 rem(10);
			margin: 0 rem(5);

			&.open { // &:hover
				background-color: $white;
				//box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.2);
				//box-shadow: 0 0 5px 0px rgba(0,0,0,0.2);
				position: relative;

				a {
					display: block;
					color: $black;
					border-bottom: none;
					padding-bottom: 0;
				}

				.sub-menu {
					display: flex;
					background-color: $white;
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.sub-menu {
			position: absolute;
			top: $nav-height;
			left: 0;
			flex-direction: column;
			box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.2);
			padding: .6rem 0;
			min-width: 11.25rem; // Hardcode the largest subnav

			opacity: 0;
			visibility: hidden;

			.current_page_item > a {
				color: $darkgrey;
			}

			li {
				height: rem(28);

				&:hover {
					box-shadow: none;
				}

				a {
					line-height: rem(25);
					text-transform: capitalize;
					font-weight: 400;

					&:hover {
						color: $darkgrey;
					}
				}
			}
		}

		.current_page_item a,
		.current_page_parent a {
			border-bottom: solid 2px $black;
		}

	} // END Media Queries Desktop

}

%quicknav__icon {
	cursor: pointer;
	position: relative; // make z-index work
	z-index: 11;
	line-height: $nav-height;

	&:hover {
		color: $darkgrey;
	}
}

.quicknav {
	position: absolute;
	top: 0;
	right: rem(25);
	@media #{$phone} {
		right: rem(15);
	}

	a, span {
		line-height: $nav-height;
		font-size: 1.3rem;
		padding: 0 .7rem;

		@extend %quicknav__icon;
	}

	&__shop {
		right: .5rem;

		&.empty span {
			display: none;
		}

		span {
			position: absolute;
		    top: 16px;
		    left: 23px;
		    font-family: effra,sans-serif;
		    border: 2.5px solid #000;
		    border-radius: 100%;
		    padding: 0;
		    width: 20px;
		    height: 20px;
		    background-color: $yellow;
		    box-sizing: border-box;
		    line-height: 16px;
		    text-align: center;
		    font-size: .8rem;
		    font-weight: 600;
		}

		&:hover {
			color: $white !important;

			span {
				border-color: $white;
			}
		}
	}

	/*
	&__search {
		position: relative;

		input {
			display: none;
			position: relative;
			right: -45px;
			background-color: transparent;
			border: solid 2px $black;
			border-radius: 1.2rem;
			line-height: 1.2rem;
			padding: .2rem 2.2rem .2rem .8rem;
			width: rem(220);
			outline: none;
		}

		.quicknav & {
			padding: 0;
		}

		> label {
			padding: 0 .7rem;
		}

		i, label {
			@extend %quicknav__icon;
		}


		&--open {
			input {
				display: inline-block;
			}
		}
	}
	*/

	&__hamburger {

		@media #{$nav-desktop} {
			display: none;
		}

		@media #{$nav-mobile} {
			display: inline-block;
		}
	}
}


/**
 *	Breadcrumb
 */

.breadcrumb {
	margin-bottom: .7rem;

	&__item {
		color: $darkgrey;
		font-size: .9rem;
		margin-right: .2rem;

		&--current {
			border-bottom: solid 1px $darkgrey;
		}
	}

}


/**
 * 	Frontpage
 */

.home {

	.header {
		margin-bottom: 0;
		@media #{$nav-desktop} {
			background-color: transparent;
		}
	}

	.main {
		flex-direction: column;
		margin: -8.5rem 0 0;
		z-index: 11;

		> div {
			margin-bottom: 3rem;
		}
	}

}

.front {

	&__image {
		position: relative;
		top: 0;
		left: 0;

		display: flex;
		justify-content: center;
		align-items: center;

		min-height: rem(450);
		padding: $nav-height 0 rem(60);
		width: 100%;
		background: {
			size: cover;
			position: center;
			color: $yellow;
		}

		&::after {
			content: '';
		    position: absolute;
		    background: $white;
		    bottom: -40px;
		    left: 0;
		    width: 100%;
		    height: 100px;
		    z-index: 10;
		    transform: skewY(-1.8deg);
		}
	}

	&__title {
		max-width: 850px;
		width: 80%;
		text-align: center;
		font-size: 3rem;
		font-weight: 700;
		line-height: 1.3;
		letter-spacing: 3px;

		@media #{$large} {
			font-size: 3rem;
		}

		@media #{$medium} {
			font-size: 2rem;
		}

		@media #{$phablet} {
			width: 90%;
			font-size: 1.5rem;
		}

		@media #{$phone} {
			width: 90%;
			font-size: 1.3rem;
		}
	}

	&__content {
		margin: 0 auto;
		text-align: center;

		@media #{$medium}, #{$large} {
			width: 75%;
		}

		@media #{$small} {
			width: 100%;
		}
	}
}

.quicklink {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;

	&__box {
		//flex-basis: 23%;
		flex: 1 0 auto;
	    box-sizing: border-box;

	    @media #{$large} {
			flex-basis: 23%;
			margin: 1%;
	    	padding: 1.6rem 2%;
		}

		@media #{$phablet}, #{$medium} {
			flex-basis: 46%;
			margin: 2%;
			padding: 6%;
		}

		@media #{$phone} {
			flex-basis: 100%;
			margin: 0 0 6%;
			padding: 10%;
		}

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
		background-color: $white;
		@include transition();

		img {
			//max-width: 70px;
			//width: 100%; // this doens't work in Edge
			width: auto;
			max-height: 4rem; // important: align title on same line
		}

		h2 {
			text-align: center;
		}

		i {
			font-size: 1.8rem;
		}

		&:hover {
			background-color: $lightgrey;
			color: $black;
		}

		&:active {
			background-color: $yellow;
		}
	}

	&--page {
		padding: 1rem 0 .5rem;

		.quicklink__box {
			border: none !important;
			color: $black;
			flex-grow: 0;

			@media #{$large} {
				flex-basis: 48%;
			}

			&:first-child {
				flex-grow: 1;
			}
		}

	}
}

.news {
	display: flex;
	justify-content: space-around;
	align-items: stretch;
	flex-wrap: wrap;
	margin-top: 1rem;
	margin-bottom: 0 !important;


	&__box {
		flex-basis: 48%;

		@media #{$small} {
			flex-basis: 100%;
			margin-bottom: 3%;
		}

		background-color: $lightgrey;
		box-sizing: border-box;
		padding: 1.5rem;
		margin-bottom: 2%;
		text-align: center;
		@include transition();

		&:hover {
			background-color: $yellow;
		}

		@media #{$phone} {
			margin-bottom: 5%;
		}

		/*
		&:hover {
			color: $black;

			i {
				color: $darkgrey;
			}
		}
		*/

		i {
			font-size: 1.8rem;
		}
	}
}


/**
 * Main
 */

.main {
	@media #{$large} {
		max-width: #{($wrapper-width + 60)};
		margin: #{($nav-height + 60)} 0 2rem;
		padding: 0 rem(30);
	}

	@media #{$small}, #{$medium} {
		max-width: #{($wrapper-width + 50)};
		margin: #{($nav-height + 50)} 0 2rem;
		padding: 0 rem(25);
	}

	@media #{$phone} {
		max-width: #{($wrapper-width + 30)};
		margin: #{($nav-height + 30)} 0 2rem;
		padding: 0 rem(15);
	}

	//border: solid 3px $lightgrey;
	width: 100%;
	box-sizing: border-box;

	display: flex;
	flex: 1 0 auto;

	@media #{$small}, #{$medium} {
		flex-direction: column;
	}

	@media #{$small} {
		margin-bottom: 0;
	}
}

.content { // .article
	//width: 100%;
	flex-grow: 1;

	h1 {
		font-weight: 400;
	}

	a { /* a:not(.box__link) */
		@extend %content_link;
	}
}

%content_link {
	color: $darkgrey;
	border-bottom: solid 1px $darkgrey;

	@include transition();

	&:hover {
		color: $black;
		border-bottom: solid 1px $black;
	}
}

/**
 * Box
 */

.grid {
	display: flex;
	//justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 4rem;

	@media #{$small} {
		margin-top: 2rem;
	}

	&--shop {
		margin-top: 2rem;
	}

	&__item {
		/*
		&:last-child {
			margin-right: auto;
			margin-left: 2.6666%;
		}
		*/

		&--nobtn {
			padding-bottom: 1.5rem;
		}

		// Form
		&--fullwidth {
			padding: 1.4rem 1.8rem .6rem;

			@media #{$phone} {
				padding: 1.4rem 0 .6rem;
			}
		}

		// People
		&--four {

			@media #{$laptop}, #{$desktop} {
				flex: 0 1 23%;

				margin-right: 2.6666%;

				&:nth-child(4n+0) {
					margin-right: 0;
				}
			}

			@media #{$tablet} {
				flex: 0 1 30%;

				margin-right: 3%;

				&:nth-child(3n+0) {
					margin-right: 0;
				}
			}

			@media #{$phablet} {
				flex: 0 1 48%;

				margin-right: 4%;

				&:nth-child(2n+0) {
					margin-right: 0;
				}
			}

			@media #{$phone} {
				flex: 0 1 100%;

				margin-right: 0;
			}
		}

		// Immo
		&--two {
			flex: 0 1 48%;
			margin-right: 4%;

			@media #{$phone} {
				flex: 0 1 100%;
				margin-right: 0;
			}

			&:nth-child(2n+0) {
				margin-right: 0;
			}
		}
	}
}

.box {
	background-color: $lightgrey;

	@media #{$laptop}, #{$desktop} {
		margin-bottom: 1.8rem;
	}

	@media #{$medium} {
		margin-bottom: 1.4rem;
	}

	@media #{$phablet} {
		margin-bottom: 1rem;
	}

	@media #{$phone} {
		margin-bottom: rem(22);
	}

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-bottom: none !important;

	/*
	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 1.5rem;
		text-align: center;
	}
	*/

	&__image {
		width: 100%;
		height: auto;

		&:after {
			content: '';
			display: block;
			padding-bottom: 64.444444446%;
		}

		&--avatar {
			display: block;
			background-color: rgba($darkgrey, .2);
		}

		&--monochrome {
			filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
    		filter: gray; /* IE6-9 */
    		-webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
		}
	}

	&__title {
		margin: 1.2rem 1rem .2rem;

		+ p {
			margin: 0 1rem;
			flex-grow: 1;
		}
	}

	&__description {
		margin: 0 1rem;
		flex-grow: 1;

		~ span { // btn in box - mail encryption
			display: inline-flex;
		}
	}

	.content &__link { // specifity
		@extend %link;
	}

	&__form {
		display: none;
	}

}


/**
 * Sidebar
 */

.sidebar {
	flex: 1 0 rem(340);
	flex-grow: 0;

	@media #{$large} {
		margin-left: 3rem;
		margin-top: 8rem;
	}

	@media #{$medium} {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	@media #{$small}, #{$medium} {
		margin-top: 2rem;
		margin-left: 0;
	}

	&--shop {
		@media #{$small}, #{$medium} {
			flex-basis: auto;
		}
	}

	&__box {
		display: flex;
		flex-direction: column;

		margin-bottom: 1.6rem;
		background-color: $lightgrey;

		@media #{$medium} {
			flex-basis: 48%;
			box-sizing: border-box;
		}
	}

	// Variations
	&_text,
	&_list {
		padding: 1.4rem 1.8rem;

		.pdf {
			display: inline-block;

			margin-bottom: 5px;
			line-height: 1.4;
			border-bottom: solid 1px $darkgrey;
			color: $darkgrey;
			margin-right: auto;

			@include transition();

			&:hover {
				color: $black;
			}
		}
	}

	&_image {
		line-height: 0;
	}
}


/**
 * Map
 */

.map {
	position: relative;
	bottom: 0;
    left: 0;
    right: 0;
    margin: 3rem 0 -100px;
    height: 600px;
    width: 100%;
}



/**
 * Footer
 */

.footer {
	position: relative;
	background-color: $footerbg;
	color: $white;
	width: 100%;
	z-index: 50;
	margin-top: rem(80);

	&:before {
		content: '';
	    position: absolute;
	    background: $footerbg;
	    top: -40px;
	    left: 0;
	    width: 100%;
	    height: 100px;
	    z-index: -1;
	    transform: skewY(-1.8deg);
	}

	h4 {
		color: $yellow;
	}

	a {
		color: $footertext;
		border-bottom: solid 1px $footertext;
		@include transition();

		&:hover {
			color: $lightgrey;
		}
	}

	&__inner {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 0 auto;
		@media #{$phablet}, #{$medium}, #{$large} {
			padding: 2rem rem(25) 1rem;
		}

		@media #{$phone} {
			padding: 1rem rem(15) 0;
		}

		max-width: #{$wrapper-width + 50};
	}

	&__section {
		margin: 1% 1% 2rem 1%;
		min-width: rem(240);
	}

	// Form
	.html__content {
		position: relative;
		@include transition();

		input[type=email]:hover {
			border-color: $yellow;
		}
	}

	input[type=email] {
		background-color: $footerbg;
		border: solid 2px $lightgrey;
		color: $white;
		padding-right: 2.5rem;
	}

	input[type=submit] {
		display: block;
		position: absolute !important;
		top: 2px;
		right: 2px;
		bottom: 2px;
		width: 2.5rem;
    	margin-left: 22px;
	    font-family: FontAwesome;
	    background-color: transparent !important;
	    z-index: 1;
	    padding: .25rem 1rem .25rem .5rem !important;
	    color: $white !important;
	    font-size: 1.2rem !important;
	    margin-left: 0;
	    cursor: pointer;
	    @include transition();

		&:hover {
			color: $yellow !important;
		}
	}

	// Social Media
	.social {
		margin: 1rem 0 .2rem;

		&__link {
			border-bottom: none;

			i {
				font-size: 1.4rem;
				color: $white;
				margin-right: .4rem;
				@include transition();

				&:hover {
					color: $yellow;
				}
			}
		}
	}

}



/**
 * Form
 */

.box__form,
.wpcf7,
.wpcf7-form,
.wpcf7-form label {
	width: 100%;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="submit"],
textarea,
select {
	-webkit-appearance: none;
	outline: none;
	box-shadow: none;
	padding: .3rem .8rem .3rem;
	font-size: 100%;
	color: $darkgrey;
	border: none;
	border-radius: 0;
	width: 100%;
	box-sizing: border-box;
}

input[type="submit"] {
	display: none;
}

textarea {
	height: 8rem;
	resize: vertical;
}

/*
input[type="submit"] {
	cursor: pointer;
	border: none;
	background-color: $darkgrey;
	color: $white;
	@extend %font-bold;

	&:hover {
		background-color: $grey;
	}
}
*/

input[type="radio"] {
	font-size: 100%;
	color: $darkgrey;
}

::-ms-input-placeholder {
	color: lighten($darkgrey, 25);
	opacity: 1;
}

.sendForm.submitting {
	opacity: 1;
    background-color: #ffd500;
    color: #000;
    border-color: #ffd500;
    padding-right: 2.5rem!important;

    &:after {
    	font-family: WooCommerce;
	    content: "\e01c";
	    vertical-align: top;
	    -webkit-font-smoothing: antialiased;
	    font-weight: 400;
	    position: absolute;
	    margin-left: .5rem;
	    animation: spin 2s linear infinite;
    }

    .wpcf7-form.invalid ~ & {
		display: none;
    }
}


/**
 * Plugins
 */

// Contact Form 7
.ajax-loader {
	display: none;
	width: auto;
}


.wpcf7-radio {
	display: inline-flex;
	margin: .2rem 0 1rem;

	/*
	@include phone {
		flex-wrap: wrap;

		.wpcf7-list-item {
			margin-left: 0 !important;
		}
	}
	*/
}

.wpcf7-list-item.last {
	margin-left: 1.5rem;
}



form div.wpcf7-response-output {
	margin: 1rem 0 2rem;
	box-sizing: border-box;
	border: none;
	color: $white;
	padding: .5rem .8rem;
}

div.wpcf7-validation-errors {
	background-color: darkorange;
}

div.wpcf7-mail-sent-ok {
	background-color: darkgreen;
}

div.wpcf7-mail-sent-ng,
div.wpcf7-spam-blocked {
	background-color: red;
}

form span.wpcf7-not-valid-tip {
	margin-top: .4rem;
	color: darkorange;
	text-align: left;
	margin-left: .8rem;
}

.wpcf7-form-control-wrap {
	display: block;
	margin-top: .2rem;
}


/**
 * 	WooCommerce
 */

.woocommerce {

	/*
		Archive
	 */

	.box__image--shop {
		@extend .box__image--avatar;
		width: calc(100% - 14px);
		margin-top: 7px;
		background: {
			size: contain;
			repeat: no-repeat;
			position: center;
			color: $white;
		}
	}

	a.grid__item {
		border-bottom: none;

		p, .box__title {
			color: $black;
		}

		&:hover {
			border-bottom: none;

			background-color: $yellow;

		}

	}

	// Price
	.price {
		font-size: 1rem;
		font-weight: 400;
		color: $black;
		margin: rem(20) 0 rem(25);
	}

	.price-to-pay {
		font-size: 1.8em;
		font-weight: 700;
		margin-left: rem(3);
	}

	.price-reduction {
		text-decoration: line-through;
		display: block;
	}

	/*
		Single
	 */

	&.single-product {

		h1 {
			margin-bottom: 0;
		}

		.subtitle {
			font-weight: 400;
			margin-top: 5px;
		}

		.price {
			margin: rem(20) 0 rem(10);
		}
	}

	.added_to_cart {
		@extend %content_link;
	}

	/*
		Buttons
	 */

	.return-to-shop a.button {
		margin-left: 0;
	}

	.sidebar a.button,
	.form-row input.button,
	.return-to-shop a.button,
	.shipping-calculator-form button.button,
	.shop_table [name=update_cart],
	#payment #place_order,
	.cart_totals .wc-proceed-to-checkout > a.checkout-button {
		@extend .btn;
		padding: .6rem 1.2rem !important;

		&.loading {
			opacity: 1;
			background-color: $yellow;
			color: $darkgrey;
			border-color: $yellow;
			padding-right: 2.5rem !important;
		}

		&.added {
			background-color: $yellow;
			color: $darkgrey;
			border-color: $yellow;
		}
	}

	.cart_totals .wc-proceed-to-checkout {
		padding: 0;

		> a.checkout-button {
			background-color: transparent;
			display: inline-block;
			float: right;
		}
	}

	a.added_to_cart {
		padding-top: 0 !important;
		margin-bottom: 1rem;
	}

	.shipping-calculator-form button.button {
		margin: 0;
	}

	#calc_shipping_country_field,
	#calc_shipping_state_field {
		display: none;
	}


	.woocommerce table.shop_table_responsive tr:nth-child(2n) td, .woocommerce-page table.shop_table_responsive tr:nth-child(2n) td {
		background-color: $white;
	}


}


.woocommerce-page .woocommerce {

	/*
		Checkout
	 */

	table.shop_table {
 		border: none !important;
 	}

	[name=update_cart] {
		width: auto;
		margin: rem(5) 0 0 0 !important;
	}

	> form {
		//background-color: $lightgrey;
		margin-top: 1rem;

		thead {
			//display: none;
		}

		table.shop_table td {
			border-top: none !important;
		}

		.cart_item td {
			border-bottom: 1px solid rgba(0,0,0,.1);
		}

		.product-name a,
		.product-thumbnail a {
			border-bottom: none;
			color: $black;
		}


		.cart_item {
			.quantity .qty {
				background-color: $lightgrey;
				background-color: transparent;
				color: $black;
			}

			a.remove {
				color: $black !important;

				&:hover {
					color: $black !important;
					background: $yellow !important;
				}
			}
		}
	}

	.cart_totals {
		margin-top: 1.5rem;

		h2 {
			display: none;
		}
	}


	/*
		Kassa
	 */

	.cart-collaterals {
		margin-top: 2rem;
	}

	#payment #place_order {
		float: left !important;
		width: auto;
		margin: 0;
	}

	.woocommerce-checkout #payment {
		background-color: $white;
	}

	ul.payment_methods {
		border-bottom: none !important;
	}

	.woocommerce-checkout-review-order {
		.cart_item td {
			border-bottom: none !important;
		}

		table.shop_table td {
			border-top: 1px solid rgba(0,0,0,.1) !important;
		}

		tbody {
			border-bottom: 1px solid rgba(0,0,0,.1);
		}
	}

	ul#shipping_method .amount {
		margin-left: rem(8);
	}


	/*
		Account
	 */

	.login {
		max-width: rem(380);

		input.button {
			display: block;
			width: auto;
		}
	}

	.edit-account input.button {
		@extend .btn;
		padding: .6rem 1.2rem !important;
		display: block;
		width: auto;
	}

	.woocommerce-MyAccount-navigation {
		ul {
			list-style: none;
		}

		li a {
			text-transform: uppercase;
			font-weight: 700;
			line-height: 1.8;
		}

		.is-active > a {
			border-bottom: solid 2px $black;
		}
	}

	table.my_account_orders .order-actions .button {
		@extend .btn;
		margin: 0 !important;
	}



	/*
		Input
	 */

	label {
		margin-left: rem(5);
	}

	input, textarea {
		background-color: $lightgrey;
		padding: rem(10) rem(8);
	}
}

.woocommerce-checkout .login {
	max-width: 100% !important;
}


/*
	Message
 */

.woocommerce-page {

	.woocommerce-message,
	.woocommerce-info {
		border: none;
		border-top-color: $yellow;
		padding: .7rem 2rem .7rem 3.5rem !important;
		line-height: rem(39);

		&:before {
			color: $black;
			top: rem(12);
		}

		+ .woocommerce-message,
		+ .woocommerce-info {
			margin-bottom: rem(10) !important;
		}

		.button {
			@extend .btn;
			margin: 0 !important;
			padding: .6rem 1.2rem !important;
		}
	}

}